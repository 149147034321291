<template>
  <div class="content">
    <div class="contentTop">
      <nav-bar/>
      <div class="gifImg">
        交付云驾驶舱
      </div>
    </div>
    <div class="contentMain">
      <div class="mainLeft">
        <div class="leftTop">
          <div class="title">
            <div>
              <span>客户构成</span>
            </div>
          </div>
          <div class="customer">
            <div id="customerFunnel"></div>
          </div>
          <div class="boxFoot"></div>
        </div>
        <div class="leftCenter">
          <div class="title">
            <div>
              <span>单量统计表</span>
            </div>
          </div>
          <div class="ticketCount">
            <div id="barCount"></div>
          </div>
          <div class="boxFoot"></div>
        </div>
        <div class="leftBottom">
          <div class="title">
            <div>
              <span>项目资金流水</span>
            </div>
          </div>
          <div class="projectFunds">
            <div class="funds">
              <div class="fundsCentent"><countTo :startVal="0" :endVal="156.95" :duration="2000" :decimals="2"></countTo>万</div>
              <div id="fundsGauge"></div>
              <div class="fundsTitle">成品加工订金</div>
            </div>
            <div class="funds1">
              <div class="fundsCentent"><countTo :startVal="0" :endVal="2652.6" :duration="2000" :decimals="2"></countTo>万</div>
              <div id="fundsGaugeOne"></div>
              <div class="fundsTitle">成品加工结算</div>
            </div>
            <div class="funds2">
              <div class="fundsCentent"><countTo :startVal="0" :endVal="996.00" :duration="2000" :decimals="2"></countTo>万</div>
              <div id="fundsGauge2"></div>
              <div class="fundsTitle">成品供应订金</div>
            </div>
            <div class="funds3">
              <div class="fundsCentent"><countTo :startVal="0" :endVal="4568.0" :duration="2000" :decimals="2"></countTo>万</div>
              <div id="fundsGauge3"></div>
              <div class="fundsTitle">成品供应结算</div>
            </div>
            <!--            <div id="customerFunnel"></div>-->
          </div>
          <div class="boxFoot"></div>
        </div>
      </div>
      <div class="mainCenter">
        <div class="centerTop">
          <div class="topTip">
            <div class="tip">
              <p><countTo class="countTip" :startVal="0" :endVal="600" :duration="2000" :decimals="0"></countTo>个出库工单</p>
              <p>涵盖<countTo class="countTip" :startVal="0" :endVal="30" :duration="2000" :decimals="0"></countTo>个省</p>
            </div>
            <div class="boxFoot"></div>
          </div>
          <div class="topProgress">
            <div id="progress"></div>
          </div>
          <div class="topMap">
            <div id="map"></div>
          </div>
        </div>
        <div class="centerBottom">
          <div class="centerBotLeft">
            <div class="title">
              <div>
                <span>石种产量排行榜</span>
              </div>
            </div>
            <div class="leftStone">
              <div id="stoneProduction"></div>
            </div>
            <div class="boxFoot"></div>
          </div>
          <div class="centerBotRight">
            <div class="title">
              <div>
                <span>项目类型分析</span>
              </div>
            </div>
            <div class="product">
              <div class="productLeft">
                <div id="leftProduct"></div>
              </div>
              <div class="productRight">
                <div id="rightProduct"></div>
              </div>
            </div>
            <div class="boxFoot"></div>
          </div>
        </div>
      </div>
      <div class="mainRight">
        <div class="rightTop">
          <div class="title">
            <div>
              <span>组织活跃分析</span>
            </div>
          </div>
          <div class="organize">
            <div id="organize"></div>
          </div>
          <div class="boxFoot"></div>
        </div>
        <div class="rightCenter">
          <div class="title">
            <div>
              <span>供应商人员分布</span>
            </div>
          </div>
          <div class="supplier">
            <div id="supplier"></div>
          </div>
          <div class="boxFoot"></div>
        </div>
        <div class="rightBottom">
          <div class="title">
            <div>
              <span>项目统筹</span>
            </div>
          </div>
          <div class="projectCount">
            <div class="countTop">
              <p class="p1">
                <span>总项目数</span>
                <countTo class="countCon" :startVal="0" :endVal="159" :duration="2000" :decimals="0"></countTo>
              </p>
              <div class="p2">
                <span>项目创建频次</span>
                <div class="pinci">
                  <span class="span1">9个/月</span>
                  <span class="ytb">月同比<span>↑</span> 30%</span>
                </div>
              </div>
              <p class="p3">
                <span class="pSpan">项目面积</span>
                <countTo class="countCon" :startVal="0" :endVal="159650245" :duration="3000" :decimals="2"></countTo>
              </p>
            </div>
            <div class="countBot">
              <div class="pieTip">
                <span class="countCon"><countTo :startVal="0" :endVal="99.99" :duration="2000" :decimals="2"></countTo>%</span>
                <span>完整率</span>
              </div>
              <div id="countPie"></div>
            </div>
          </div>
          <div class="boxFoot"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import chinaMap from "../assets/map/china.json";
//导入数字滚动
import countTo from "vue-count-to"
import NavBar from './navBar.vue';
export default {
  name: "deliveryContent",
  data(){
    return{
      myChart:undefined,
      myMap:undefined,
      myChart1: undefined,
      myChart2: undefined,
      myChart3: undefined,
      myChart4: undefined,
      myChart5: undefined,
      myChart6: undefined,
      myChart7: undefined,
      myChart8: undefined,
      myChart9: undefined,
      myChart10: undefined,
      myChart11: undefined,
      myChart12: undefined,
      geoCoordMap:{
        '新疆玛纳斯基地': [86.22, 44.30],
        '九江': [116.00, 29.70],
        '新乡': [116.402217, 35.311657],
        ' ': [79.92, 37.12],
        '  ': [86.85, 47.70],
        '若羌县': [88.17, 39.02],
        '上海': [121.4648, 31.2891],
        '东莞': [113.8953, 22.901],
        '东营': [118.7073, 37.5513],
        '中山': [113.4229, 22.478],
        '临汾': [111.4783, 36.1615],
        '临沂': [118.3118, 35.2936],
        '丹东': [124.541, 40.4242],
        '丽水': [119.5642, 28.1854],
        '乌鲁木齐': [87.9236, 43.5883],
        '佛山': [112.8955, 23.1097],
        '保定': [115.0488, 39.0948],
        '兰州': [103.5901, 36.3043],
        '包头': [110.3467, 41.4899],
        '北京': [116.4551, 40.2539],
        '北海': [109.314, 21.6211],
        '南京': [118.8062, 31.9208],
        '南宁': [108.479, 23.1152],
        '南昌': [116.0046, 28.6633],
        '南通': [121.1023, 32.1625],
        '厦门': [118.1689, 24.6478],
        '台州': [121.1353, 28.6688],
        '合肥': [117.29, 32.0581],
        '呼和浩特': [111.4124, 40.4901],
        '咸阳': [108.4131, 34.8706],
        '哈尔滨': [127.9688, 45.368],
        '唐山': [118.4766, 39.6826],
        '嘉兴': [120.9155, 30.6354],
        '大同': [113.7854, 39.8035],
        '大连': [122.2229, 39.4409],
        '天津': [117.4219, 39.4189],
        '太原': [112.3352, 37.9413],
        '威海': [121.9482, 37.1393],
        '宁波': [121.5967, 29.6466],
        '宝鸡': [107.1826, 34.3433],
        '宿迁': [118.5535, 33.7775],
        '常州': [119.4543, 31.5582],
        '广州': [113.5107, 23.2196],
        '廊坊': [116.521, 39.0509],
        '延安': [109.1052, 36.4252],
        '张家口': [115.1477, 40.8527],
        '徐州': [117.5208, 34.3268],
        '德州': [116.6858, 37.2107],
        '惠州': [114.6204, 23.1647],
        '成都': [103.9526, 30.7617],
        '扬州': [119.4653, 32.8162],
        '承德': [117.5757, 41.4075],
        '拉萨': [91.1865, 30.1465],
        '无锡': [120.3442, 31.5527],
        '日照': [119.2786, 35.5023],
        '昆明': [102.9199, 25.4663],
        '杭州': [119.5313, 29.8773],
        '枣庄': [117.323, 34.8926],
        '柳州': [109.3799, 24.9774],
        '株洲': [113.5327, 27.0319],
        '武汉': [114.3896, 30.6628],
        '汕头': [117.1692, 23.3405],
        '江门': [112.6318, 22.1484],
        '沈阳': [123.1238, 42.1216],
        '沧州': [116.8286, 38.2104],
        '河源': [114.917, 23.9722],
        '泉州': [118.3228, 25.1147],
        '泰安': [117.0264, 36.0516],
        '泰州': [120.0586, 32.5525],
        '济南': [117.1582, 36.8701],
        '济宁': [116.8286, 35.3375],
        '海口': [110.3893, 19.8516],
        '淄博': [118.0371, 36.6064],
        '淮安': [118.927, 33.4039],
        '深圳': [114.5435, 22.5439],
        '清远': [112.9175, 24.3292],
        '温州': [120.498, 27.8119],
        '渭南': [109.7864, 35.0299],
        '湖州': [119.8608, 30.7782],
        '湘潭': [112.5439, 27.7075],
        '滨州': [117.8174, 37.4963],
        '潍坊': [119.0918, 36.524],
        '烟台': [120.7397, 37.5128],
        '玉溪': [101.9312, 23.8898],
        '珠海': [113.7305, 22.1155],
        '盐城': [120.2234, 33.5577],
        '盘锦': [121.9482, 41.0449],
        '石家庄': [114.4995, 38.1006],
        '福州': [119.4543, 25.9222],
        '秦皇岛': [119.2126, 40.0232],
        '绍兴': [120.564, 29.7565],
        '聊城': [115.9167, 36.4032],
        '肇庆': [112.1265, 23.5822],
        '舟山': [122.2559, 30.2234],
        '苏州': [120.6519, 31.3989],
        '莱芜': [117.6526, 36.2714],
        '菏泽': [115.6201, 35.2057],
        '营口': [122.4316, 40.4297],
        '葫芦岛': [120.1575, 40.578],
        '衡水': [115.8838, 37.7161],
        '衢州': [118.6853, 28.8666],
        '西宁': [101.4038, 36.8207],
        '西安': [109.1162, 34.2004],
        '贵阳': [106.6992, 26.7682],
        '连云港': [119.1248, 34.552],
        '邢台': [114.8071, 37.2821],
        '邯郸': [114.4775, 36.535],
        '郑州': [113.4668, 34.6234],
        '鄂尔多斯': [108.9734, 39.2487],
        '重庆': [107.7539, 30.1904],
        '金华': [120.0037, 29.1028],
        '铜川': [109.0393, 35.1947],
        '银川': [106.3586, 38.1775],
        '镇江': [119.4763, 31.9702],
        '长春': [125.8154, 44.2584],
        '长沙': [113.0823, 28.2568],
        '长治': [112.8625, 36.4746],
        '阳泉': [113.4778, 38.0951],
        '青岛': [120.4651, 36.3373],
        '韶关': [113.7964, 24.7028]
      },
    }
  },
  mounted() {
    this.getCustomerFunnel()
    this.getMap()
    this.getBarCount()
    this.getFundsGauge()
    this.getFundsGaugeOne()
    this.getFundsGaugeTwo()
    this.getFundsGaugeThree()
    this.getstoneProductionBar()
    this.getLeftProductType()
    this.getRightProductType()
    this.getSupplierPie()
    this.getCountPie()
    this.getOrganize()
    this.getProgress()
  },
  components:{
    countTo,
    NavBar
  },
  destroyed() {
    this.myChart.dispose()
    this.myMap.dispose()
    this.myChart1.dispose()
    this.myChart2.dispose()
    this.myChart3.dispose()
    this.myChart4.dispose()
    this.myChart5.dispose()
    this.myChart6.dispose()
    this.myChart7.dispose()
    this.myChart8.dispose()
    this.myChart9.dispose()
    this.myChart10.dispose()
    this.myChart11.dispose()
    this.myChart12.dispose()
  },
  methods:{
    routerInlsmc(){
      this.$router.push('/lsmc')
    },
    routerIndbsc() {
      this.$router.push('/dbsc')
    },
    routerInlsdc() {
      this.$router.push('/lsdc')
    },
    getCustomerFunnel(){
      let customerFunnel = document.getElementById('customerFunnel')
      this.myChart = echarts.init(customerFunnel);
      let option = {
        color: ['#298DFF', '#2EC25B', '#FBD337','#6236FF'],
        // barWidth: 8,
        // tooltip: {
        //   axisPointer: {
        //     type: 'shadow'
        //   },
        //   trigger: 'item',
        //   formatter: '{b} : {c}'
        // },
        // grid: {
        //   left: '1%',
        //   right: '4%',
        //   bottom: '3%',
        //   containLabel: true
        // },
        series: [
          {
            name: '',
            type: 'funnel',
            // width:"40%",
            bottom:30,
            left: '10%',
            right: '55%',
            gap: 3,
            sort:'ascending',
            itemStyle: {  //去掉默认白色边框线
              borderWidth: 0,
              // borderColor: '#fff'
            },
            label: {
              show: true,
              formatter: "{b|{b}} "+"{b|}",
              rich: {
                a: {
                  color: '#595959',
                  fontSize: 16
                },
                b: {
                  color: '#999',
                  fontSize: 16
                }
              }
            },
            labelLine: {
              length: 80,
              lineStyle: {
                // width: 1,
                // color: '#ECECEC',
                // type: 'solid'
              }
            },
            data: [
              { name: '143个设计师',value: 40 },
              { name: '26家供应商，共3718人',value: 60},
              { name: '56个平台秘书',value:20 },
              { name: '72家企业,共341人',value:80 },
            ]
          },

        ]
      };

      this.myChart.setOption(option);
      const _this = this
      window.addEventListener("resize",function(){
        _this.myChart.resize();
      });
    },
    convertData(data){
      let res = [];
      for (let i = 0; i < data.length; i++) {
        let dataItem = data[i];
        let fromCoord = this.geoCoordMap[dataItem[0].name];
        let toCoord = this.geoCoordMap[dataItem[1].name];
        if (fromCoord && toCoord) {
          res.push([{
            coord: fromCoord
          }, {
            coord: toCoord
          }]);
        }
      }
      return res;
    },
    getMap(){
      echarts.registerMap('china', {geoJSON:chinaMap})
      let map = document.getElementById('map')
      this.myMap = echarts.init(map);
      let QZData = [
        [{
          name: '泉州'
        }, {
          name: '泉州',
          value: 200
        }],
        [{
          name: '泉州'
        }, {
          name: '南昌',
          value: 90
        }],
        [{
          name: '泉州'
        }, {
          name: '南宁',
          value: 90
        }],
        [{
          name: '泉州'
        }, {
          name: '成都',
          value: 90
        }],
        [{
          name: '泉州'
        }, {
          name: '北京',
          value: 80
        }],
        [{
          name: '泉州'
        }, {
          name: '贵阳',
          value: 100
        }],
        [{
          name: '泉州'
        }, {
          name: '上海',
          value: 40
        }],
        [{
          name: '泉州'
        }, {
          name: '合肥',
          value: 40
        }],
        [{
          name: '泉州'
        }, {
          name: '武汉',
          value: 50
        }],
        [{
          name: '泉州'
        }, {
          name: '济南',
          value: 30
        }],
        [{
          name: '泉州'
        }, {
          name: '西安',
          value: 60
        }],
        [{
          name: '泉州'
        }, {
          name: '南京',
          value: 30
        }],
        [{
          name: '泉州'
        }, {
          name: '沈阳',
          value: 20
        }],
      ];
      let that = this
      let planePath = 'path://M.6,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705';
      // let color = ['#3ed4ff', '#ffa022', '#a6c84c'];
      let series = [
        {
          name: '泉州',
          type: 'lines',
          tooltip: {
            show:false,
          },
          zlevel: 1,
          effect: {
            show: true,
            period: 6,
            trailLength: 0.7,
            color: '#fff',
            symbolSize: 3
          },
          lineStyle: {
            normal: {
              color: '#3ed4ff',
              width: 0,
              curveness: 0.2
            }
          },
          data: this.convertData(QZData)
        }, {
          name: '泉州' ,
          type: 'lines',
          tooltip: {
            show:false,
          },
          zlevel: 2,
          effect: {
            show: true,
            period: 6,
            trailLength: 0,
            symbol: planePath,
            symbolSize: 15
          },
          lineStyle: {
            normal: {
              color: '#3ed4ff',
              width: 1,
              opacity: 0.4,
              curveness: 0.2
            }
          },
          data: this.convertData(QZData)
        }, {
          name: '',
          type: 'effectScatter',
          coordinateSystem: 'geo',
          zlevel: 2,
          rippleEffect: {
            brushType: 'stroke'
          },
          label: {
            normal: {
              show: true,
              position: 'right',
              formatter: '{b}'
            }
          },
          symbolSize: function (val) {
            return val[2] / 8;
          },
          itemStyle: {
            normal: {
              color: '#3ed4ff'
            }
          },
          data: QZData.map(function (dataItem) {
            return {
              name: dataItem[1].name,
              value: that.geoCoordMap[dataItem[1].name].concat([dataItem[1].value])
            };
          })
        },{
          name: '中国',
          type: 'map',
          map: 'china',
          zoom:1.2,
          // aspectScale:0.95,
          // top:'16.5 %',
          data: this.convertData(QZData),
          label: {
            normal: {
              position: 'right',
              show: false,
            },
            emphasis: {
              show: false,
              textStyle: {
                color: '#ffffff'
              }
            }
          },
        }
      ];
      let option = {
        // backgroundColor: '#080a20',
        title: {
          left: 'left',
          textStyle: {
            color: '#fff'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show:false,
          orient: 'vertical',
          top: 'bottom',
          left: 'right',
          // data: ['北京 Top10', '上海 Top10', '广州 Top10'],
          textStyle: {
            color: '#fff'
          },
          selectedMode: 'single'
        },
        geo: {
          map: 'china',
          // top:0,
          // left:0,
          zoom: 1.7,
          // center: [109.1162, 34.2004],
          center:[103.5901, 36.3043],
          label: {
            emphasis: {
              show: false
            }
          },
          roam: true,
          itemStyle: {
            normal: {
              areaColor: '#142957',
              borderColor: '#0692a4'
            },
            emphasis: {
              areaColor: '#0b1c2d'
            }
          }
        },
        series: series
      };
      that.myMap.setOption(option);
      window.addEventListener("resize",function(){
        that.myMap.resize();
      });
    },
    getBarCount(){
      let barCount = document.getElementById('barCount')
      this.myChart1 = echarts.init(barCount);
      let option = {
        tooltip: {
          show:false,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          top:20,
          bottom:20,
          itemHeight: 10,
          itemWidth: 10,
          textStyle: {
            color:'#999',
            fontSize:15
          },
          data: [ '工程单数据值','工程单月同比','出库单数据值', '出库单月同比'],
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '0%',
          containLabel: true
        },
        xAxis: [
          {
            show:false,
            type: 'value',
          }
        ],
        yAxis: [
          {
            type: 'category',
            axisTick: {
              show: false
            },
            axisLabel: {
              textStyle: {
                show:true,
                fontFamily:'微软雅黑',
                color: "#999",
                fontSize: 16,
              },
            },
            data: ['总耗时', '总工单', '完成准时率', '异常单', '本月完成', '本月新增'],
          }
        ],
        series: [
          {
            name: '出库单数据值',
            type: 'bar',
            stack: 'Total1',
            color:'#E886A8',
            label: {
              show: true,
              position: [50, -15],
              color:'#999',
              fontSize: 15,
              formatter: function (params) {
                return params.data.ticket;
              },
            },
            itemStyle: {
              //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius:[0, 8, 8, 0]
              }
            },
            emphasis: {
              focus: 'series'
            },
            data: [{value:350,ticket:'501,000.56h'},
              {value:280,ticket:'150,000单'},
              {value:380,ticket:'99%'},
              {value:120,ticket:'1单'},
              {value:230,ticket:'5,000单'},
              {value:180,ticket:'950单'}]
          },
          {
            name: '工程单数据值',
            type: 'bar',
            stack: 'Total1',
            color:'#2CC8F8',
            label: {
              show: true,
              position: [10, -15],
              color:'#999',
              fontSize: 15,
              formatter: function (params) {
                return params.data.ticket;
              },
            },
            itemStyle: {
              //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius:[8, 0, 0, 8]
              }
            },
            emphasis: {
              focus: 'series'
            },
            data: [{value:-350,ticket:'500,000,568.56h'},
              {value:-380,ticket:'50,458,856单'},
              {value:-300,ticket:'90%'},
              {value:-260,ticket:'9单'},
              {value:-330,ticket:'45,590单'},
              {value:-200,ticket:'50,596单'}]
          },
          {
            name: '出库单月同比',
            type: 'bar',
            stack: 'Total',
            color:'#D02A88',
            label: {
              show: true,
              position: 'right',
              color:'#999',
              formatter: function (params) {
                return params.data.ticket;
              },
            },
            itemStyle: {
              //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius:[0, 8, 8, 0]
              }
            },
            emphasis: {
              focus: 'series'
            },
            data:[{value:0,ticket:''},
              {value:0,ticket:''},
              {value:0,ticket:''},
              {value:0,ticket:''},
              {value:300,ticket:'80%'},
              {value:90,ticket:'2%'}]
          },
          {
            name: '工程单月同比',
            type: 'bar',
            stack: 'Total',
            color:'#304FF0',
            barGap: '100%',
            barCategoryGap:'60%',
            label: {
              show: true,
              position: 'left',
              color:'#999',
              formatter: function (params) {
                return params.data.ticket;
              },
            },
            itemStyle: {
              //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius:[8, 0, 0, 8]
              }
            },
            emphasis: {
              focus: 'series'
            },
            data: [{value:0,ticket:''},
              {value:0,ticket:''},
              {value:0,ticket:''},
              {value:0,ticket:''},
              {value:-150,ticket:'20%'},
              {value:-280,ticket:'20%'}]
          },
        ]
      };
      this.myChart1.setOption(option);
      const _this = this
      window.addEventListener("resize",function(){
        _this.myChart1.resize();
      });
    },
    getFundsGauge(){
      let fundsGauge = document.getElementById('fundsGauge')
      this.myChart2 = echarts.init(fundsGauge);
      let data = [{
        value: 50,
        name: '156.95万',
        title: {
          show:false,
          color:'#fff',
          offsetCenter: ['15%', '0%']
        },
        detail: {
          valueAnimation: true,
          offsetCenter: ['0%', '50%']
        }
      }]
      let option= {
        series: [
          {
            type: 'gauge',
            color:'#6236FF',
            startAngle: 90,
            endAngle: -270,
            center:['50%','50%'],
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 0,
                borderColor: '#464646'
              }
            },
            axisLine: {
              lineStyle: {
                width: 6
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: data,
            title: {
              fontSize: 14
            },
            detail: {
              show:false,
              width: 20,
              height: 14,
              fontSize: 14,
              color: 'auto',
              borderColor: 'auto',
              borderRadius: 20,
              borderWidth: 1,
              formatter: '{value}%'
            }
          }
        ]
      }
      this.myChart2.setOption(option);
      const _this = this
      window.addEventListener("resize",function(){
        _this.myChart2.resize();
      });
    },
    getFundsGaugeOne(){
      let fundsGaugeOne = document.getElementById('fundsGaugeOne')
      this.myChart3 = echarts.init(fundsGaugeOne);
      let data = [{
        value: 65,
        name: '156万',
        title: {
          show:false,
          color:'#fff',
          offsetCenter: ['15%', '0%']
        },
      }]
      let option= {
        series: [
          {
            type: 'gauge',
            color:'#6236FF',
            startAngle: 90,
            endAngle: -270,
            center:['50%','50%'],
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 0,
                borderColor: '#464646'
              }
            },
            axisLine: {
              lineStyle: {
                width: 6
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: data,
            title: {
              fontSize: 14
            },
            detail: {
              show:false,
              width: 20,
              height: 14,
              fontSize: 14,
              color: 'auto',
              borderColor: 'auto',
              borderRadius: 20,
              borderWidth: 1,
              formatter: '{value}%'
            }
          }
        ]
      }
      this.myChart3.setOption(option);
      const _this = this
      window.addEventListener("resize",function(){
        _this.myChart3.resize();
      });
    },
    getFundsGaugeTwo(){
      let fundsGaugeOne = document.getElementById('fundsGauge2')
      this.myChart4 = echarts.init(fundsGaugeOne);
      let data = [{
        value: 48,
        name: '156万',
        title: {
          show:false,
          color:'#fff',
          offsetCenter: ['15%', '0%']
        },
      }]
      let option= {
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            color:'#FA6400',
            center:['50%','50%'],
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 0,
                borderColor: '#fff'
              }
            },
            axisLine: {
              lineStyle: {
                width: 6
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: data,
            title: {
              fontSize: 14
            },
            detail: {
              show:false,
              width: 20,
              height: 14,
              fontSize: 14,
              color: 'auto',
              borderColor: 'auto',
              borderRadius: 20,
              borderWidth: 1,
              formatter: '{value}%'
            }
          }
        ]
      }
      this.myChart4.setOption(option);
      const _this = this
      window.addEventListener("resize",function(){
        _this.myChart4.resize();
      });
    },
    getFundsGaugeThree(){
      let fundsGaugeOne = document.getElementById('fundsGauge3')
      this.myChart5 = echarts.init(fundsGaugeOne);
      let data = [{
        value: 65,
        name: '156万',
        title: {
          show:false,
          // color:'#fff',
          // offsetCenter: ['50%', '0%']
        },
      }]
      let option= {
        series: [
          {
            type: 'gauge',
            color:'#FA6400',
            startAngle: 90,
            endAngle: -270,
            center:['50%','50%'],
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 0,
                borderColor: '#464646'
              }
            },
            axisLine: {
              lineStyle: {
                width: 6
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: data,
            title: {
              fontSize: 14
            },
            detail: {
              show:false,
              width: 20,
              height: 14,
              fontSize: 14,
              color: 'auto',
              borderColor: 'auto',
              borderRadius: 20,
              borderWidth: 1,
              formatter: '{value}%'
            }
          }
        ]
      }
      this.myChart5.setOption(option);
      const _this = this
      window.addEventListener("resize",function(){
        _this.myChart5.resize();
      });
    },
    getstoneProductionBar(){
      let stoneProduction = document.getElementById('stoneProduction')
      this.myChart6 = echarts.init(stoneProduction);
      let option = {
        legend: {
          orient: 'horizontal',
          top: '18',
          textStyle: {
            color:'#fff',
            fontSize:14
          },
          itemHeight: 10,
          itemWidth: 10,
        },
        tooltip: {},
        grid:{
          bottom:40,
          top:50,
        },
        xAxis: {
          type: 'category',
          data: ['鱼肚白', '云多拉灰', '蓝金', '新亚米黄', '伊丽莎白', '新米黄', '罗马木板'],
          axisLabel:{
            interval: 0,
            textStyle: {
              show:true,
              fontFamily:'微软雅黑',
              color: "#999",
              fontSize: 16,
            },}
        },
        yAxis: {},

        series: [
          { type: 'bar',
            name:'生产面积',
            data: [7, 6.9, 9.5, 26.5, 9.5, 5.7, 16.6],
          },
          { type: 'bar',
            name:'出材率',
            data: [3.9, 4.2, 5.7, 16.6, 7, 9.5, 7],
          }]
      }
      this.myChart6.setOption(option);
      const _this = this
      window.addEventListener("resize",function(){
        _this.myChart6.resize();
      });
    },
    getLeftProductType(){
      let leftProduct = document.getElementById('leftProduct')
      this.myChart7 = echarts.init(leftProduct);
      let data = [
        { value: 56, name: '成品加工',bfb:'35%' },
        { value: 56, name: '成品供应',bfb:'35%' },
        { value: 47, name: '其他',bfb:'30%' },
      ]
      let option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal',
          bottom: 'bottom',
          itemHeight: 12,
          itemWidth: 12,
          textStyle: {
            color:'#fff',
            fontSize:14
          },
          formatter: function (params) {
            let value
            for(let i in data){
              if(data[i].name == params){
                value = data[i].value
              }
            }
            return params+':'+value+'个';
          },
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: '50%',
            bottom:40,
            top:0,
            emphasis:{
              scaleSize:12,
              label:{
                fontSize:14,
                color:'#fff',
                formatter: function (params) {
                  return params.data.bfb;
                },
              }
            },
            label:{
              position:'inside',
              color:'#fff',
              formatter: function (params) {
                return params.data.bfb;
              },

            },
            data: data
          }
        ]
      }
      this.myChart7.setOption(option);
      const _this = this
      window.onresize = function () {
        _this.myChart7.resize();
        // .resize后加括号哦，这里还可以写其他的事件
      };
      let currentIndex = 0;
      setInterval(function () {
        var dataLen = option.series[0].data.length;
        // 取消之前高亮的图形
        _this.myChart7.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: currentIndex
        });
        currentIndex = (currentIndex + 1) % dataLen;
        // 高亮当前图形
        _this.myChart7.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: currentIndex,
        });
      }, 3000);
    },
    getRightProductType(){
      let rightProduct = document.getElementById('rightProduct')
      this.myChart8 = echarts.init(rightProduct);
      let data = [
        { value: 103, name: '多楼多栋',bfb:'65%' },
        { value: 56, name: '单户',bfb:'35%' },
      ]
      let option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal',
          bottom: 'bottom',
          itemHeight: 12,
          itemWidth: 12,
          textStyle: {
            color:'#fff',
            fontSize:14
          },
          formatter: function (params) {
            let value
            for(let i in data){
              if(data[i].name == params){
                value = data[i].value
              }
            }
            return params+':'+value+'个';
          },
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: '50%',
            bottom:40,
            top:0,
            emphasis:{
              scaleSize:12,
              label:{
                fontSize:14,
                color:'#fff',
                formatter: function (params) {
                  return params.data.bfb;
                },
              }
            },
            label:{
              position:'inside',
              color:'#fff',
              formatter: function (params) {
                return params.data.bfb;
              },

            },
            data: data
          }
        ]
      }
      this.myChart8.setOption(option);
      const _this = this
      window.addEventListener("resize",function(){
        _this.myChart8.resize();
      });
      let currentIndex = 0;
      setInterval(function () {
        var dataLen = option.series[0].data.length;
        // 取消之前高亮的图形
        _this.myChart8.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: currentIndex
        });
        currentIndex = (currentIndex + 1) % dataLen;
        // 高亮当前图形
        _this.myChart8.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: currentIndex,
        });
      }, 3000);
    },
    getSupplierPie(){
      let supplier = document.getElementById('supplier')
      this.myChart9= echarts.init(supplier);
      let data =[
        { name: 'CAD深化',value:156 },
        { name: '采购', value:56 },
        { name: '入仓标记', value:28 },
        { name: '石材精灵',value:186 },
        { name: '切割', value:59 },
        { name: '加工', value:146 },
        { name: '排版', value:35 },
        { name: '装箱', value:49 },
        { name: '运输',value:96 }
      ]
      let option = {
        legend: {
          orient: 'horizontal',
          bottom: 'bottom',
          left:10,
          right:10,
          itemHeight: 10,
          itemWidth: 10,
          textStyle: {
            color:'#999',
            fontSize:14
          },
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 0
          },
        },
        series:[
          {
            type: 'pie',
            radius: [30, 65],
            bottom:10,
            data:data,
            emphasis:{
              scaleSize:14,
              label:{
                alignTo: 'edge',
                formatter: '{name|{b}}: {num|{c}人}',
                color:'#fff',
                minMargin: 10,
                edgeDistance: 10,
                lineHeight: 16,
                rich: {
                  num: {
                    fontSize: 16,
                    color: '#fff'
                  }
                }
              },
            },
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 0
            },
            label: {
              alignTo: 'edge',
              formatter: '{name|{b}}: {num|{c}人}',
              color:'#999',
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 16,
              fontSize:16,
              rich: {
                num: {
                  fontSize: 16,
                  color: '#999'
                }
              }
            },
          }
        ],
      }
      this.myChart9.setOption(option);
      const _this = this
      window.addEventListener("resize",function(){
        _this.myChart9.resize();
      });
      let currentIndex = 0;
      setInterval(function () {
        var dataLen = option.series[0].data.length;
        // 取消之前高亮的图形
        _this.myChart9.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: currentIndex
        });
        currentIndex = (currentIndex + 1) % dataLen;
        // 高亮当前图形
        _this.myChart9.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: currentIndex,
        });
      }, 3000);
    },
    getCountPie(){
      let countPie = document.getElementById('countPie')
      this.myChart10 = echarts.init(countPie);
      let data =[
        { name: '在制中',
          value: 50,
          mj:'33,394,050.00㎡',
          // itemStyle:{
          //   borderRadius: 5
          // },
        },
        { name: '已出库',
          value: 50,
          mj:'126,256,195.00㎡',
          itemStyle:{
            borderRadius: 10
          },
        },
      ]
      let option = {
        legend: {
          show:false,
          orient: 'horizontal',
          bottom: 'bottom',
          textStyle: {
            fontSize: 16,
            color:'#999',
          },
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 0
          },
        },
        series:[
          {
            type: 'pie',
            center:['50%','50%'],
            radius: [45, 50],
            startAngle:45,
            data:data,
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 0,
            },
            label: {
              alignTo: 'edge',
              formatter: function (params) {
                return params.name+`\n`+params.data.mj;
              },
              color:'#999',
              minMargin: 5,
              edgeDistance: 10,
              fontSize: 16,
              lineHeight: 18,
              rich: {
                time: {
                  fontSize: 16,
                  color: '#999'
                }
              }
            },
          }
        ]
      }
      this.myChart10.setOption(option);
      const _this = this
      window.addEventListener("resize",function(){
        _this.myChart10.resize();
      });
      _this.myChart10.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        name: '已出库'
      });
    },
    getOrganize(){
      let organize = document.getElementById('organize')
      this.myChart11 = echarts.init(organize);
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          show:false,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '0%',
          containLabel: true
        },
        xAxis: [
          {
            show:false,
            type: 'value',
          }
        ],
        yAxis: [
          {
            type: 'category',
            axisTick: {
              show: false
            },
            axisLine:{
              show:false
            },
            axisLabel: {
              textStyle: {
                show:true,
                fontFamily:'微软雅黑',
                color: "#999",
                fontSize: 16,
              },
            },
            data: [  '负责人数', '生产中的供应商','企业使用频次','合作中的企业'],
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            stack: 'Total1',
            color:'#E886A8',
            barWidth : 8,
            showBackground: false,
            label: {
              show: true,
              position: [10, -15],
              color:'#999',
              fontSize: 15,
              formatter: function (params) {
                return params.data.ticket;
              },
            },
            itemStyle: {
              //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius:[0, 8, 8, 0]
              }
            },
            emphasis: {
              focus: 'series'
            },
            data: [
              {value:0,ticket:''},
              {value:90,ticket:'30家'},
              {value:0,ticket:''},
              {value:80,ticket:'20家'},
            ]
          },
          {
            name: '',
            type: 'bar',
            stack: 'Total1',
            color:'#2CC8F8',
            barWidth : 10,
            showBackground: false,
            label: {
              show: true,
              position: [10, -15],
              color:'#999',
              fontSize: 15,
              formatter: function (params) {
                return params.data.ticket;
              },
            },
            itemStyle: {
              //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
              normal: {
                //柱形图圆角，初始化效果
                // barBorderRadius:[8, 0, 0, 8]
              }
            },
            emphasis: {
              focus: 'series'
            },
            data: [
              {value:20,ticket:'256人'},
              {value:0,ticket:''},
              {value:20,ticket:'16次/天'},
              {value:0,ticket:''},
            ]
          },
        ]
      };
      this.myChart11.setOption(option);
      const _this = this
      window.addEventListener("resize",function(){
        _this.myChart11.resize();
      });
    },
    getProgress(){
      let progress = document.getElementById('progress')
      this.myChart12 = echarts.init(progress);
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          show:false,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '0%',
          containLabel: true
        },
        xAxis: [
          {
            show:false,
            type: 'value',
          }
        ],
        yAxis: [
          {
            type: 'category',
            axisTick: {
              show: false
            },
            axisLine:{
              show:false
            },
            axisLabel: {
              textStyle: {
                show:true,
                fontFamily:'微软雅黑',
                color: "#999",
                fontSize: 16,
              },
            },
            data: ['准时率', '完整率'],
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            stack: 'Total1',
            color:'#2CC8F8',
            barWidth : 6,
            showBackground: true,
            label: {
              show: true,
              position: [150, -12],
              color:'#999',
              formatter: function (params) {
                return params.data.value+'%';
              },
            },
            itemStyle: {
              //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius:[0, 8, 8, 0]
              }
            },
            emphasis: {
              focus: 'series'
            },
            data: [
              {value:90,name:'完整率'},
              {value:90,name:'准时率'},
            ]
          },
        ]
      };
      this.myChart12.setOption(option);
      const _this = this
      window.addEventListener("resize",function(){
        _this.myChart12.resize();
      });
    }
  },

}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;

  .title{
    position: absolute;
    top: -0.25rem;
    left: 0;
    height: .52rem;
    font-size: .18rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    div{
      width: 1.8rem;
      height: 0.5rem;
      background: url("../assets/box.png") no-repeat;
      background-size: 100% 100%;
      line-height: 0.5rem;
    }
  }

  .contentTop {
    height: 1.5rem;
    width: 100%;
    font-size: .32rem;
    line-height: .32rem;
    color: #ffffff;
    position: relative;
    display: flex;
    align-items: center;
    .gifImg {
      font-size: 0.42rem;
      line-height: 0.42rem;
      width: 100%;
      min-height: .5rem;
      text-align: center;
    }
  }
  .contentMain{
    //margin-top: 0.1rem;
    font-size: 0.2rem;
    text-align: center;
    color: #ffffff;
    display: flex;
    padding: 0 .2rem 0 .2rem;
    .mainLeft{
      width: 26%;
      height:100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .leftTop{
        width: 100%;
        padding-left: 1%;
        position: relative;
        background: rgba(0,0,0,0.3);

        .customer{
          width: 100%;
          height:2.5rem;
          #customerFunnel{
            width: 100%;
            height: 100%;
          }
        }
        .boxFoot {
          position: absolute;
          bottom: 0;
          width: 100%;
          left: 0;
        }
      }
      .leftTop:before, .boxFoot:before {
        border-left: 0.02rem solid #02a6b5;
        left: 0;
      }
      .leftTop:after, .boxFoot:after {
        border-right: 0.02rem solid #02a6b5;
        right: 0;
      }
      .leftTop:before, .leftTop:after {
        border-radius: 0.03rem;
        position: absolute;
        width: .1rem;
        height: .1rem;
        content: '';
        top: 0;
        border-top: 0.02rem solid #02a6b5;
      }
      .boxFoot:before, .boxFoot:after {
        border-radius: 0.03rem;
        position: absolute;
        width: .1rem;
        height: .1rem;
        content: '';
        bottom: 0;
        border-bottom: 0.02rem solid #02a6b5;
      }
      .leftCenter{
        margin-top: 8%;
        width: 100%;
        padding-left: 1%;
        position: relative;
        background: rgba(0,0,0,0.3);

        .ticketCount{
          width: 100%;
          height: 4.5rem;
          display: flex;
          flex-direction: column;
          padding:3%;
          #barCount{
            width: 100%;
            height: 100%;
          }
          .ticketTitle{
            margin-top: 2%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 0.14rem;
            .gcd{
              padding: 0.05rem;
              border-radius: 0.05rem;
              background:#298DFF ;
            }
            .ckd{
              padding: 0.05rem;
              border-radius: 0.05rem;
              background: #298DFF;
            }
          }
          .ticketContent{
            font-size: 0.16rem;
            margin-top: 3%;
          }
        }
        .boxFoot {
          position: absolute;
          bottom: 0;
          width: 100%;
          left: 0;
        }
      }
      .leftCenter:before, .boxFoot:before {
        border-left: 0.02rem solid #02a6b5;
        left: 0;
      }
      .leftCenter:after, .boxFoot:after {
        border-right: 2px solid #02a6b5;
        right: 0;
      }
      .leftCenter:before, .leftCenter:after {
        border-radius: 3px;
        position: absolute;
        width: .1rem;
        height: .1rem;
        content: '';
        top: 0;
        border-top: 2px solid #02a6b5;
      }
      .leftBottom{
        margin-top: 5%;
        width: 100%;
        height: 32%;
        padding-left: 1%;
        position: relative;
        background: rgba(0,0,0,0.3);

        .projectFunds{
          width: 100%;
          height: 2.6rem;
          display: flex;

          .funds{
            width: 25%;
            position: relative;
            font-size: 0.14rem;
            .fundsCentent{
              position: absolute;
              right: 0.38rem;
              top: 1rem;
            }
            #fundsGauge{
              width: 100%;
              height: 100%;
            }
            .fundsTitle{
              position: absolute;
              top: 1.3rem;
              left: 0.25rem;
            }
          }
          .funds1{
            width: 25%;
            position: relative;
            font-size: 0.14rem;
            .fundsCentent{
              position: absolute;
              right: 0.35rem;
              top: 1rem;
            }
            #fundsGaugeOne{
              width: 100%;
              height: 100%;
            }
            .fundsTitle{
              position: absolute;
              top: 1.3rem;
              left: 0.25rem;
            }
          }
          .funds2{
            width: 25%;
            position: relative;
            font-size: 0.14rem;
            .fundsCentent{
              position: absolute;
              right: 0.38rem;
              top: 1rem;
            }
            #fundsGauge2{
              width: 100%;
              height: 100%;
            }
            .fundsTitle{
              position: absolute;
              top: 1.3rem;
              left: 0.25rem;
            }
          }
          .funds3{
            width: 25%;
            position: relative;
            font-size: 0.14rem;
            .fundsCentent{
              position: absolute;
              right: 0.35rem;
              top: 1rem;
            }
            #fundsGauge3{
              width: 100%;
              height: 100%;
            }
            .fundsTitle{
              position: absolute;
              top: 1.3rem;
              left: 0.25rem;
            }
          }


        }
        .boxFoot {
          position: absolute;
          bottom: 0;
          width: 100%;
          left: 0;
        }
      }
      .leftBottom:before, .boxFoot:before {
        border-left: 0.02rem solid #02a6b5;
        left: 0;
      }
      .leftBottom:after, .boxFoot:after {
        border-right: 0.02rem solid #02a6b5;
        right: 0;
      }
      .leftBottom:before, .leftBottom:after {
        border-radius: 0.03rem;
        position: absolute;
        width: .1rem;
        height: .1rem;
        content: '';
        top: 0;
        border-top: 0.02rem solid #02a6b5;
      }
      .boxFoot:before, .boxFoot:after {
        border-radius: 0.03rem;
        position: absolute;
        width: .1rem;
        height: .1rem;
        content: '';
        bottom: 0;
        border-bottom: 0.02rem solid #02a6b5;
      }
    }
    .mainCenter{
      margin:0 1%;
      width: 48%;
      height: 100%;
      .centerTop{
        position: relative;
        width: 100%;
        .topTip{
          position: absolute;
          padding: 0 15px 0 15px;
          background: rgba(0,0,0,0.3);
          .tip{
            display: flex;
            p{
              font-size: 0.2rem;
              margin-right: 10px;
              .countTip{
                font-size: .28rem;
              }
            }
          }
          .boxFoot {
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
          }
        }
        .topTip:before, .boxFoot:before {
          border-left: 0.02rem solid #02a6b5;
          left: 0;
        }
        .topTip:after, .boxFoot:after {
          border-right: 0.02rem solid #02a6b5;
          right: 0;
        }
        .topTip:before, .topTip:after {
          border-radius: 0.03rem;
          position: absolute;
          width: .1rem;
          height: .1rem;
          content: '';
          top: 0;
          border-top: 0.02rem solid #02a6b5;
        }
        .boxFoot:before, .boxFoot:after {
          border-radius: 0.03rem;
          position: absolute;
          width: .1rem;
          height: .1rem;
          content: '';
          bottom: 0;
          border-bottom: 0.02rem solid #02a6b5;
        }
        .topProgress{
          position: absolute;
          bottom:0;
          width: 26%;
          height: 1.4rem;
          #progress{
            width: 100%;
            height: 100%;
          }
        }
        .topMap{
          height: 6.6rem;
          #map{
            width: 100%;
            height: 100%;
          }
        }

      }
      .centerBottom{
        margin-top: 3%;
        width: 100%;
        display: flex;
        .centerBotLeft{
          width: 56%;
          position: relative;
          background: rgba(0,0,0,0.3);
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;

          .leftStone{
            margin-top: 3%;
            width: 100%;
            height: 3.58rem;
            #stoneProduction{
              height:100%;
              width: 100%;
            }
          }
          .boxFoot {
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
          }
        }
        .centerBotLeft:before, .boxFoot:before {
          border-left: 0.02rem solid #02a6b5;
          left: 0;
        }
        .centerBotLeft:after, .boxFoot:after {
          border-right: 0.02rem solid #02a6b5;
          right: 0;
        }
        .centerBotLeft:before, .centerBotLeft:after {
          border-radius: 0.03rem;
          position: absolute;
          width: .1rem;
          height: .1rem;
          content: '';
          top: 0;
          border-top: 0.02rem solid #02a6b5;
        }
        .boxFoot:before, .boxFoot:after {
          border-radius: 0.03rem;
          position: absolute;
          width: .1rem;
          height: .1rem;
          content: '';
          bottom: 0;
          border-bottom: 0.02rem solid #02a6b5;
        }
        .centerBotRight{
          width:  45%;
          margin-left: 2%;
          position: relative;
          background: rgba(0,0,0,0.3);
          .product{
            display: flex;
            .productLeft{
              margin-top: 3%;
              width: 45%;
              height: 3.58rem;
              #leftProduct{
                width: 100%;
                height: 100%;
              }
            }
            .productRight{
              margin-top: 3%;
              width: 45%;
              height: 3.58rem;
              #rightProduct{
                width: 100%;
                height: 100%;
              }
            }
          }
          .boxFoot {
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
          }
        }
        .centerBotRight:before, .boxFoot:before {
          border-left: 0.02rem solid #02a6b5;
          left: 0;
        }
        .centerBotRight:after, .boxFoot:after {
          border-right: 0.02rem solid #02a6b5;
          right: 0;
        }
        .centerBotRight:before, .centerBotRight:after {
          border-radius: 0.03rem;
          position: absolute;
          width: .1rem;
          height: .1rem;
          content: '';
          top: 0;
          border-top: 0.02rem solid #02a6b5;
        }
      }
    }
    .mainRight{
      width: 24%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .rightTop{
        width: 100%;
        position: relative;
        background: rgba(0,0,0,0.3);
        .organize{
          width: 100%;
          height: 2.6rem;
          #organize{
            height: 100%;
            width: 100%;
          }
        }
        .boxFoot {
          position: absolute;
          bottom: 0;
          width: 100%;
          left: 0;
        }
      }
      .rightCenter{
        width: 100%;
        margin: 5% 0;
        position: relative;
        background: rgba(0,0,0,0.3);
        //background-image: url(../assets/gz.png);
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .supplier{
          width: 100%;
          height: 3.5rem;
          #supplier{
            width: 100%;
            height: 100%;
          }
        }
        .boxFoot {
          position: absolute;
          bottom: 0;
          width: 100%;
          left: 0;
        }
      }
      .rightBottom{
        width: 100%;
        position: relative;
        background: rgba(0,0,0,0.3);
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .projectCount{
          width: 100%;
          margin-top: 0.2rem;
          .countTop{
            padding: 0 0.2rem;
            font-size: 0.16rem;
            color: #999999;
            .p1{
              padding-bottom: 0.08rem;
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid #999999;
              .countCon{
                font-size: 0.26rem;
                letter-spacing:0.06rem;
                color:#44D7B6;
                font-weight: bold;
              }
            }
            .p2{
              padding-bottom: 0.08rem;
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid #999;
              line-height: .16rem;
              .pinci{
                display: flex;
                .span1{
                  display: inline-block;
                  margin-right: 6px;
                  padding: 3px;
                  background: #1e5b6b;
                  color: #ffffff ;
                }
                .ytb{
                  padding: 3px;
                  span{
                    margin-left: 3px;
                    color: red;
                  }
                }
              }
            }
            .p3{
              width: 100%;
              display: flex;
              justify-content: space-between;

              .countCon{
                font-size: 0.26rem;
                letter-spacing:0.06rem;
                color:#44D7B6;
                font-weight: bold;
              }
            }

          }
          .countBot{
            width: 100%;
            height: 2.18rem;
            position: relative;
            #countPie{
              width: 100%;
              height: 100%;
            }
            .pieTip{
              position: absolute;
              top:1rem;
              left: 50%;
              margin-left: -0.35rem;
              font-size: 0.14rem;
              .countCon{
                display: block;
                font-size: 0.2rem;
              }
            }
          }
        }
        .boxFoot {
          position: absolute;
          bottom: 0;
          width: 100%;
          left: 0;
        }
      }

      .rightTop:before, .boxFoot:before {
        border-left: 0.02rem solid #02a6b5;
        left: 0;
      }
      .rightTop:after, .boxFoot:after {
        border-right: 0.02rem solid #02a6b5;
        right: 0;
      }
      .rightTop:before, .rightTop:after {
        border-radius: 0.03rem;
        position: absolute;
        width: .1rem;
        height: .1rem;
        content: '';
        top: 0;
        border-top: 0.02rem solid #02a6b5;
      }

      .rightCenter:before, .boxFoot:before {
        border-left: 0.02rem solid #02a6b5;
        left: 0;
      }
      .rightCenter:after, .boxFoot:after {
        border-right: 0.02rem solid #02a6b5;
        right: 0;
      }
      .rightCenter:before, .rightCenter:after {
        border-radius: 0.03rem;
        position: absolute;
        width: .1rem;
        height: .1rem;
        content: '';
        top: 0;
        border-top: 0.02rem solid #02a6b5;
      }

      .rightBottom:before, .boxFoot:before {
        border-left: 0.02rem solid #02a6b5;
        left: 0;
      }
      .rightBottom:after, .boxFoot:after {
        border-right: 0.02rem solid #02a6b5;
        right: 0;
      }
      .rightBottom:before, .rightBottom:after {
        border-radius: 0.03rem;
        position: absolute;
        width: .1rem;
        height: .1rem;
        content: '';
        top: 0;
        border-top: 0.02rem solid #02a6b5;
      }
      .boxFoot:before, .boxFoot:after {
        border-radius: 0.03rem;
        position: absolute;
        width: .1rem;
        height: .1rem;
        content: '';
        bottom: 0;
        border-bottom: 0.02rem solid #02a6b5;
      }
    }
  }
}

</style>

<template>
  <div class="index">
    <div id="load">
      <!-- 加载动画 -->
      <div class="load_img" v-if="showLoad">
        <img class="jzxz1" src="../../assets/jzxz1.png">
        <img class="jzxz2" src="../../assets/jzxz2.png">
      </div>
      <!-- 大屏内容 -->
      <div class="content" v-else>
        <delivery-content/>
      </div>
    </div>
  </div>
</template>

<script>
import deliveryContent from "@/components/deliveryContent";

export default {
  name: 'Lsdc',
  components: {
    deliveryContent
  },
  data() {
    return {
      timer: null,
      showLoad: true
    }
  },
  mounted() {
    this.setTime()
  },
  destroyed() {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    //设置定时器
    setTime() {
      const _this = this
      _this.timer = setTimeout(() => {
        _this.showLoad = false
        clearTimeout(_this.timer)
        _this.timer = setTimeout(() => {
          _this.$router.push('/dbsc')
          clearTimeout(_this.timer)
        }, 15050)
      }, 50)
    }
    // 大屏

  }
}
</script>

<style scoped>
/*样式*/
.content {
  width: 100%;
  height: 100%;
}

/* 加载旋转动画 */
#load {
  width: 100%;
  height: 100%;
  position: absolute;
  background: url(../../assets/lsdcBg.png) no-repeat #061537;
  background-size: cover;
  top: 0;
  left: 0;
  z-index: 999
}

#load .load_img {
  position: absolute;
  left: calc(50% - 182px);
  top: calc(50% - 182px);
}

.load_img img {
  position: absolute;
  left: 0;
  top: 0;
}

.load_img .jzxz1 {
  animation: xz1 8s infinite linear;
}

@keyframes xz1 {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.load_img .jzxz2 {
  animation: xz2 7s infinite linear;
}

@keyframes xz2 {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-180deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
</style>

